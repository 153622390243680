import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon, Add as AddIcon } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import { Currency } from '../../../types';
import { ResizeTextField, HookFormTextField } from '../../../common';
import { ErrorMessages } from '../../../../constants';
import ExtraInfo from './extra-info';
import { StyledInputLabel } from '../../../styled';

const useStyles = makeStyles(() => ({
  assetGridBox: {
    marginLeft: '3em',
    marginTop: '1em',
    marginBottom: '1em',
    borderLeft: '5px #ECBF3C solid',
  },
}));

export default function Address({ deletable, idPrefix, currencies, onDelete, error, asset }) {
  const classes = useStyles();
  const { control, setValue, register, watch } = useFormContext();

  const {
    fields: extraInfos,
    append: appendExtraInfos,
    remove: removeExtraInfos,
  } = useFieldArray({
    control,
    name: `${idPrefix}.raw_extra_info`,
  });

  const watchCurrency = watch(`${idPrefix}.currency`);
  const { addr_extra_info: addrExtraInfo } = watchCurrency || { addr_extra_info: [] };
  const renderExtraInfo = () => {
    if (addrExtraInfo.length === 0) {
      return null;
    }
    return (
      <Grid item container direction="column">
        <Grid item container alignItems="center">
          <Grid item>
            <StyledInputLabel>Address Extra Info(Tag, Memo)</StyledInputLabel>
          </Grid>

          <Grid item>
            <IconButton
              aria-label="add"
              onClick={() => appendExtraInfos({ key: addrExtraInfo[0], value: '' })}
              size="small"
              id={`${idPrefix}.add_memo_btn`}
              disabled={extraInfos.length === addrExtraInfo.length}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          {extraInfos.map((extraInfo, i) => (
            <ExtraInfo
              key={extraInfo.id}
              idPrefix={`${idPrefix}.raw_extra_info[${i}]`}
              addrExtraInfo={addrExtraInfo}
              onDelete={() => removeExtraInfos(i)}
              error={_.get(error, ['raw_extra_info', i])}
              defaultValue={extraInfo}
            />
          ))}
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid item container direction="column" spacing={3} className={classes.assetGridBox}>
      <Grid item container direction="column">
        <Grid item style={{ padding: 0 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton
              style={{ padding: 0 }}
              aria-label="delete"
              disabled={!deletable}
              onClick={onDelete}
              id={`${idPrefix}.close_btn`}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item>
          <Controller
            render={({ onChange, ...props }) => (
              <Autocomplete
                {...props}
                id="currency"
                options={currencies}
                groupBy={(option) => option.groupTitle}
                getOptionLabel={(option) => {
                  if (!option) {
                    return '';
                  }
                  return `${option.currency_name} (${option.currency_symbol})`;
                }}
                getOptionSelected={(option, value) => option.currency_id === value.currency_id}
                renderInput={(params) => (
                  <ResizeTextField
                    {...params}
                    id="currency_input"
                    name="currency_input"
                    label="Asset Type"
                    error={_.get(error, ['currency'])}
                    helperText={_.get(error, ['currency']) && 'Required'}
                  />
                )}
                onChange={(__, v) => {
                  removeExtraInfos();
                  onChange(v);
                }}
              />
            )}
            onChange={([, currency]) => {
              setValue(`${idPrefix}.currency`, currency);
            }}
            name={`${idPrefix}.currency`}
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={(currencies || []).find(
              (currency) => currency.currency_id === asset.currency.currency_id,
            )}
          />
        </Grid>
      </Grid>

      <Grid item>
        <HookFormTextField
          id={`${idPrefix}.address`}
          name={`${idPrefix}.address`}
          label="Wallet Address"
          forwardedRef={register({
            required: true,
            pattern: /^[A-Za-z0-9-]+$/,
          })}
          onChange={(e) => {
            setValue(`${idPrefix}.address`, e.target.value);
          }}
          error={_.get(error, ['address'])}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            if (type === 'required') {
              return ErrorMessages.Required;
            }
            if (type === 'duplicated') {
              return ErrorMessages.InvalidAddressDuplicated;
            }
            return ErrorMessages.InvalidPureTextFormat;
          }}
          defaultValue={asset.address}
        />
      </Grid>
      {renderExtraInfo()}
    </Grid>
  );
}

Address.defaultProps = {
  currencies: undefined,
  error: undefined,
};

Address.propTypes = {
  deletable: PropTypes.bool.isRequired,
  idPrefix: PropTypes.string.isRequired,
  currencies: PropTypes.arrayOf(Currency),
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.shape({
    address: PropTypes.shape({
      type: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  }),
  asset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currency: PropTypes.shape({
      groupTitle: PropTypes.string.isRequired,
      currency_id: PropTypes.string.isRequired,
      currency_symbol: PropTypes.string.isRequired,
      currency_name: PropTypes.string.isRequired,
      is_active: PropTypes.bool.isRequired,
      is_token: PropTypes.bool.isRequired,
      raw_extra_info: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        }),
      ),
    }),
    address: PropTypes.string.isRequired,
  }).isRequired,
};
