import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'lodash';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel, HookFormTextField } from '../common';
import { ErrorMessages } from '../../constants';
import { isValidURL } from '../../utils/utils';
import FixedIP from './fixedIP';
import GoLive from './goLive';
import PublicKey from './publicKey';

export default function Bridge({ goLiveDefaultValue, goLiveForwardedRef, goLiveDisabled }) {
  const { setValue, errors, register } = useFormContext();
  return (
    <>
      <MarginVerticalSection>
        <RequiredLabel htmlFor="vasp_api" value="Beneficiary VASP API endpoint URL" isRequired />
        <HookFormTextField
          id="vasp_receive_url"
          name="vasp_receive_url"
          label={
            <>
              API endpoint for beneficiary VASP to receive API request from Bridge Server&nbsp;
              <a
                title="Bridge"
                href="https://developers.sygna.io/reference#bridge-3"
                target="_blank"
                rel="noreferrer noopener"
              >
                (See Here)
              </a>
              &nbsp;.
              <br />
              Example:&quot;beneficiary.example/api/v1.1.0/bridge/transaction/permission-request&quot;
            </>
          }
          placeholder="Input beneficiary VASP API endpoint URL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: '16px' }}>
                https://
              </InputAdornment>
            ),
          }}
          paddingLabel={20}
          onChange={(e) => setValue('vasp_receive_url', e.target.value)}
          forwardedRef={register({
            required: true,
            validate: isValidURL,
          })}
          error={errors.vasp_receive_url}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            if (type === 'required') {
              return ErrorMessages.Required;
            }
            return ErrorMessages.InvalidURLFormat;
          }}
        />
      </MarginVerticalSection>

      <MarginVerticalSection>
        <RequiredLabel htmlFor="vasp_api" value="Beneficiary validate address endpoint URL" />
        <HookFormTextField
          id="callback_validate_addr_url"
          name="callback_validate_addr_url"
          label={
            <>
              API endpoint for beneficiary VASP to provide validation of address for
              originator&nbsp;
              <a
                title="Bridge"
                href="https://developers.sygna.io/reference#bridge-3"
                target="_blank"
                rel="noreferrer noopener"
              >
                (See Here)
              </a>
              &nbsp;.
              <br />
              Example:&quot;beneficiary.example/api/v1.1.0/bridge/transaction/permission-request&quot;
            </>
          }
          placeholder="Input beneficiary validate address endpoint URL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: '16px' }}>
                https://
              </InputAdornment>
            ),
          }}
          paddingLabel={20}
          onChange={(e) => setValue('callback_validate_addr_url', e.target.value)}
          forwardedRef={register({
            validate: isValidURL,
          })}
          error={errors.callback_validate_addr_url}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            return ErrorMessages.InvalidURLFormat;
          }}
        />
      </MarginVerticalSection>

      <MarginVerticalSection>
        <RequiredLabel
          htmlFor="vasp_api"
          value="Beneficiary receive notification of txid endpoint URL"
          isRequired
        />
        <HookFormTextField
          id="callback_txid_url"
          name="callback_txid_url"
          label={
            <>
              API endpoint for beneficiary VASP to receive notification of the transaction id was
              post by originator from Bridge Server&nbsp;
              <a
                title="Bridge"
                href="https://developers.sygna.io/reference#bridge-3"
                target="_blank"
                rel="noreferrer noopener"
              >
                (See Here)
              </a>
              &nbsp;.
              <br />
              Example:&quot;beneficiary.example/api/v1.1.0/bridge/transaction/txid&quot;
            </>
          }
          placeholder="Input beneficiary receive notification of txid endpoint URL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: 16 }}>
                https://
              </InputAdornment>
            ),
          }}
          paddingLabel={40}
          onChange={(e) => setValue('callback_txid_url', e.target.value)}
          forwardedRef={register({
            required: true,
            validate: isValidURL,
          })}
          error={errors.callback_txid_url}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            if (type === 'required') {
              return ErrorMessages.Required;
            }
            return ErrorMessages.InvalidURLFormat;
          }}
        />
      </MarginVerticalSection>
      <MarginVerticalSection>
        <RequiredLabel
          htmlFor="callback_vasp_server_health_check_url"
          value="VASP server health check endpoint URL"
        />
        <HookFormTextField
          id="callback_vasp_server_health_check_url"
          name="callback_vasp_server_health_check_url"
          label={
            <>
              API endpoint for Sygna to check VASP’s health status <b>every 1 hour</b>.<br />
              This will help you and the counterparty VASP to have clear idea of counterparty VASP’s
              server status.
              <br />
              If Sygna is able to hit this endpoint URL means your server is &quot;<b>Healthy</b>
              &quot;, otherwise <b>&quot;Unhealthy&quot;</b>.<br />
              If you don’t set this endpoint URL, then your server status will be{' '}
              <b>&quot;Unknown&quot;</b> to other VASPs.
              <br />
              Example:&quot;vaspexample/api/v1.1.0/bridge/server-health-check&quot;
            </>
          }
          placeholder="Input beneficiary server health check endpoint URL"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" style={{ fontSize: 16 }}>
                https://
              </InputAdornment>
            ),
          }}
          paddingLabel={70}
          onChange={(e) => setValue('callback_vasp_server_health_check_url', e.target.value)}
          forwardedRef={register({
            validate: isValidURL,
          })}
          error={errors.callback_vasp_server_health_check_url}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            return ErrorMessages.InvalidURLFormat;
          }}
        />
      </MarginVerticalSection>
      <FixedIP />
      <GoLive
        defaultValue={goLiveDefaultValue}
        forwardedRef={goLiveForwardedRef}
        disabled={goLiveDisabled}
      />
      <PublicKey />
    </>
  );
}

Bridge.propTypes = {
  goLiveDefaultValue: PropTypes.string.isRequired,
  goLiveForwardedRef: PropTypes.shape(React.createRef()).isRequired,
};
