import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { Button } from '@material-ui/core';
import _ from 'lodash';
import { MarginVerticalSection, StyledInputLabel } from '../../../styled';
import { RequiredLabel } from '../../../common';
import { Currency } from '../../../types';
import Address from './address';

export default function Asset({ currencies }) {
  const { control, errors } = useFormContext();
  const {
    fields: assets,
    append: appendAssets,
    remove: removeAssets,
  } = useFieldArray({
    control,
    name: 'raw_assets',
  });

  const handleClicked = () => {
    appendAssets({
      currency: {
        groupTitle: '',
        currency_id: 'sygna:0x80000000',
        currency_symbol: 'BTC',
        currency_name: 'Bitcoin',
        is_active: true,
        is_token: false,
        addr_extra_info: [],
      },
      address: '',
      raw_extra_info: null,
    });
  };

  return (
    <>
      <MarginVerticalSection>
        <RequiredLabel
          htmlFor="vasp_api"
          value="Whitelist your wallet addresses with the Travel Rule Email Protocol"
          isRequired
        />
        <StyledInputLabel>
          You will receive a email confirmation link if any of the whitelisted wallet addresses are
          transacting with Sygna protocol VASPs.
        </StyledInputLabel>
      </MarginVerticalSection>
      <MarginVerticalSection>
        <Button
          variant="outlined"
          id="add_new_asset_btn"
          onClick={handleClicked}
          style={{ textTransform: 'none' }}
        >
          Add a new Asset
        </Button>
      </MarginVerticalSection>
      {assets.map((asset, i) => (
        <Address
          key={asset.id}
          asset={asset}
          idPrefix={`raw_assets[${i}]`}
          currencies={currencies}
          deletable={assets.length > 1}
          onDelete={() => removeAssets(i)}
          error={_.get(errors, ['raw_assets', i])}
        />
      ))}
    </>
  );
}

Asset.propTypes = {
  currencies: PropTypes.arrayOf(Currency).isRequired,
};
