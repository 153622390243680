import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'lodash';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel, HookFormTextField } from '../common';
import { ErrorMessages } from '../../constants';
import { isValidHostUrl } from '../../utils/utils';
import FixedIP from './fixedIP';
import GoLive from './goLive';
import PublicKey from './publicKey';

export default function Hub({ goLiveDefaultValue, goLiveForwardedRef, goLiveDisabled }) {
  const { setValue, errors, register } = useFormContext();
  return (
    <>
      <FixedIP />
      <GoLive
        defaultValue={goLiveDefaultValue}
        forwardedRef={goLiveForwardedRef}
        disabled={goLiveDisabled}
      />
      <PublicKey />
    </>
  );
}

Hub.propTypes = {
  goLiveDefaultValue: PropTypes.string.isRequired,
  goLiveForwardedRef: PropTypes.shape(React.createRef()).isRequired,
};
