import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Receiver from './receiver';
import { Currency } from '../../types';
import Asset from './asset';

const useStyles = makeStyles(() => ({
  progress: {
    color: green[500],
  },
}));

export default function EmailProtocol({ currencies }) {
  const classes = useStyles();

  if (!currencies) {
    return <CircularProgress size={24} className={classes.progress} />;
  }

  if (currencies.length === 0) {
    return <p style={{ color: 'red', fontWeight: 400 }}>currencies not found</p>;
  }

  return (
    <>
      <Receiver />
      <Asset currencies={currencies} />
    </>
  );
}

EmailProtocol.defaultProps = {
  currencies: undefined,
};

EmailProtocol.propTypes = {
  currencies: PropTypes.arrayOf(Currency),
};
