import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import _ from 'lodash';
import { Grid, MenuItem, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { HookFormTextField } from '../../../common';
import { ErrorMessages } from '../../../../constants';

export default function ExtraInfo({ defaultValue, idPrefix, addrExtraInfo, onDelete, error }) {
  const { setValue, register, control } = useFormContext();
  return (
    <Grid item container spacing={1} justifyContent="center" alignContent="center">
      <Grid item xs={4}>
        <Controller
          as={
            <HookFormTextField
              paddingLabel={8}
              select
              options={addrExtraInfo.map((key) => (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              ))}
            />
          }
          control={control}
          name={`${idPrefix}.key`}
          defaultValue={defaultValue.key}
          id={`${idPrefix}.key`}
          label="key"
        />
      </Grid>
      <Grid item xs={4}>
        <HookFormTextField
          id={`${idPrefix}.value`}
          name={`${idPrefix}.value`}
          label="value"
          forwardedRef={register({
            required: true,
            pattern: /^[A-Za-z0-9-]+$/,
          })}
          onChange={(e) => setValue(`${idPrefix}.value`, e.target.value)}
          error={_.get(error, ['value'])}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            if (type === 'required') {
              return ErrorMessages.Required;
            }
            return ErrorMessages.InvalidPureTextFormat;
          }}
          defaultValue={defaultValue.value}
        />
      </Grid>
      <Grid item xs={4}>
        <IconButton
          aria-label="delete"
          onClick={onDelete}
          size="small"
          id={`${idPrefix}.close_btn`}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

ExtraInfo.defaultProps = {
  error: undefined,
};

ExtraInfo.propTypes = {
  idPrefix: PropTypes.string.isRequired,
  addrExtraInfo: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDelete: PropTypes.func.isRequired,
  error: PropTypes.object,
  defaultValue: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
};
