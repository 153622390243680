import React from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel, HookFormTextField, ResizeTextField } from '../common';
import { ErrorMessages } from '../../constants';

const useStyles = makeStyles(() => ({
  info: {
    padding: '16px',
    marginTop: '20px',
    border: 'dashed 1px #104935',
    color: '#192038',
  },
  infoh4: {
    fontSize: '16px',
    fontFamily: 'sans-serif',
    marginBottom: '0',
  },
  infop: {
    fontSize: '16px',
    fontFamily: 'sans-serif',
    color: `rgba(0, 0, 0, 0.54)`,
    margin: '0px',
    marginTop: '10px',
  },
}));

export default function PublicKey() {
  const { setValue, errors, register } = useFormContext();
  const classes = useStyles();

  return (
    <>
      <MarginVerticalSection>
        <RequiredLabel htmlFor="pem_pubkey" value="VASP Public Key" isRequired />
        <ResizeTextField
          name="begin_public_key"
          value="-----BEGIN PUBLIC KEY-----"
          label="For other VASPs to encrypt private information. Please see Key pair generation for more
                    details."
          disabled
          paddingLabel={20}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <HookFormTextField
          id="pem_pubkey"
          name="pem_pubkey"
          placeholder="Input your VASP public key"
          multiline
          forwardedRef={register({
            required: true,
          })}
          onChange={(e) => setValue('pem_pubkey', e.target.value)}
          error={errors.pem_pubkey}
          rows={2}
          parseError={(e) => {
            const type = _.get(e, ['type'], undefined);
            if (!type) {
              return '';
            }
            return ErrorMessages.Required;
          }}
        />
        <ResizeTextField
          name="end_public_key"
          value="-----END PUBLIC KEY-----"
          disabled
          InputProps={{
            disableUnderline: true,
          }}
          paddingLabel={20}
        />
      </MarginVerticalSection>

      <div className={classes.info}>
        <h4 className={classes.infoh4}>Key pair generation</h4>
        <p className={classes.infop}>
          This short guide will help you to generate a key pair via the OpenSSL command line tool,
          in order to provide the public key to Bridge.
          <br />
          <br />
          Generate an EC private key, of size 256, and output it to a file named key.pem: &gt;&gt; $
          openssl ecparam -name secp256k1 -genkey -out key.pem
          <br />
          <br />
          Extract the public key from the key pair, which can be used in the encryption: &gt;&gt; $
          openssl ec -in key.pem -pubout -out public.pem
          <br />
          <br />
          After running these two commands you will generate two files: key.pem and public.pem in
          your working directory. Please provide us the public key in the public.pem file and take
          care not to expose the private key. Ensure that you only do so on a system you consider to
          be secure.
          <br />
          <br />
          Convert private key PEM to HEX: &gt;&gt; $ openssl ec -in key.pem -outform DER | tail -c
          +8 | head -c 32 | xxd -p -c 32
          <br />
          <br />
          Example of the entire public.pem file:
          <br />
          <br />
          <div style={{ background: '#E2E8E6' }}>-----BEGIN PUBLIC KEY-----</div>
          <div style={{ background: '#E2E8E6' }}>
            MFYwEAYHKoZIzj0CAQYFK4EEAAoDQgAEt6UQHBPi4UvdVfLKhesWFf8bglF6Qz/m
          </div>
          <div style={{ background: '#E2E8E6' }}>
            togexOGvBf+yPONxUJqKF+ifPCx2tkHeOBJ/8N0K/gLRP6uxdJVB9g==
          </div>
          <div style={{ background: '#E2E8E6' }}>-----END PUBLIC KEY-----</div>
        </p>
      </div>
    </>
  );
}
