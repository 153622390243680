import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { yellow } from '@material-ui/core/colors';
import * as _ from 'lodash';
import FormHelperText from '@material-ui/core/FormHelperText';
import { FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import Layout from '../components/layout';
import {
  parseGraphQLErrorMessage,
  transferDataToSubmitArg,
  getCountryCodes,
  scrollToRef,
  navigate,
  getRegistrationType,
  checkJWTExpired,
  getToken,
  getRoleId,
  getVaspCode,
} from '../utils/utils';
import { CREATE_UPDATE_REQUEST, GET_CURRENCIES, GET_REVIEW, GET_UPDATE_REQUEST } from '../crud';
import logo from '../images/logo.png';
import bundleProductLogo from '../images/sygna_bundle_logo.png';
import { Operations, ErrorMessages, VaspRegisterType, OperationStatus } from '../constants';
import {
  ErrorDialog,
  CustomDialog,
  LoadingButton,
  RequiredLabel,
  HookFormTextField,
  CustomDateTimePicker,
} from '../components/common';
import Bridge from '../components/create-vasp-update-request/bridge';
import Hub from '../components/create-vasp-update-request/hub';
import EmailProtocol from '../components/create-vasp-update-request/email-protocol';
import AccountExpiration from '../components/create-vasp-update-request/accountExpiration';
import { MarginVerticalSection } from '../components/styled';
import AuthenticatedPage from '../components/authenticated-page';
import Navigator from '../components/navigator';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      fontFamily: 'sans-serif',
      fontSize: '16px',
    },
  },
  container: {
    width: '100%',
    paddingRight: '40px',
    paddingLeft: '40px',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  headerContainer: {
    paddingLeft: '30px',
    paddingRight: '30px',
    borderBottom: '7px solid #104935',
  },
  logo: {
    display: 'inline-block',
    marginBottom: '6px',
    maxWidth: '165px',
  },
  content: {
    background: 'white',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  intro: {
    padding: '30px 0 20px',
  },
  introTitle: {
    fontSize: '18px',
    marginBottom: '0',
    color: '#222b45',
    lineHeight: '24px',
    fontWeight: 'bold',
  },
  ctaBlock: {
    padding: '20px 0 30px',
  },
  ctaBlockText: {
    marginTop: '0',
    marginBottom: '20px',
  },
  ctaBtn: {
    position: 'relative',
    display: 'inline-block',
    textDecoration: 'none',
    textTransform: 'capitalize',
    boxShadow: 'none',
    height: 'auto',
    color: 'white',
    backgroundColor: '#104935',
    '&:hover': {
      color: '#c39e37',
      backgroundColor: '#197353',
    },
  },
  formControl: {
    width: '100%',
  },
  buttonProgress: {
    color: yellow[800],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  header: {
    width: '100%',
    padding: '30px 0 0',
  },
  normalFontSize: {
    fontSize: '16px',
  },
}));

function Logo({ className, registrationType }) {
  let imgSrc;
  if (registrationType === VaspRegisterType.Gate || registrationType === VaspRegisterType.Hub) {
    imgSrc = bundleProductLogo;
  } else {
    imgSrc = logo;
  }
  const serviceName = getRegistrationType(registrationType);
  return <img id="logo" className={className} src={imgSrc} alt={serviceName} />;
}
Logo.propTypes = {
  className: PropTypes.string.isRequired,
  registrationType: PropTypes.number,
};
Logo.defaultProps = {
  registrationType: VaspRegisterType.Bridge,
};

export default function CreateVaspUpdateRequest({ location }) {
  const token = getToken();
  if (!token || checkJWTExpired(token)) {
    navigate('/');
    return null;
  }
  // extract registration type from token
  // new version use registration_type, but incase there are old valid token
  // so old "type" will also work

  const id = location.state ? location.state.parameter.id : {};
  const [regType, setRegType] = useState();
  const [serviceName, setServiceName] = useState();
  const [informDialogOpen, setInformDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [error, setError] = useState('');

  const [createLoading, setCreateLoading] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);
  const [existUpdateRequest, setExistUpdateRequest] = useState(false);
  const [isGtrActived, setIsGtrActived] = useState(false);
  const [isCODEActive, setIsCODEActive] = useState(false);

  const [vaspDetail, setVaspDetail] = useState({
    vasp_email: '',
    vasp_name: '',
    operation: '',
    vasp_code_prefix: '',
    country_code: '',
    city_code: '',
    branch_code: '',
    company_id: '',
    vasp_category: '',
    vasp_receive_url: '',
    vasp_fixed_ip: '',
    pem_pubkey: '',
    go_live_dt: '',
    raw_receivers: [],
    notification_receivers: [],
    assets: [],
    raw_assets: [],
    estimated_customer_number: 0,
    estimated_annual_transaction_volume: 0,
    estimated_annual_travel_rule_data_transfer_number: 0,
    paidInteroperability: '',
  });
  const [currencies, setCurrencies] = useState(undefined);

  const hookFormProps = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    handleSubmit,
    register,
    errors,
    setValue,
    control,
    reset,
    setError: setFormError,
    getValues,
  } = hookFormProps;

  const getCurrencies = useQuery(GET_CURRENCIES, {
    variables: {},
    context: {
      headers: {
        authorization: token,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const getReview = useQuery(GET_REVIEW, {
    variables: {
      id,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    onCompleted: (data) => {
      if (!_.has(data, 'getReview')) {
        setVaspDetail({});
        return;
      }

      const vaspRegister = data.getReview;
      setRegType(vaspRegister.registration_type);
      setServiceName(getRegistrationType(regType));
      vaspRegister.vasp_code = getVaspCode(vaspRegister);
      if (_.has(vaspRegister, 'vasp_receive_url')) {
        vaspRegister.vasp_receive_url =
          (vaspRegister.vasp_receive_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'callback_validate_addr_url')) {
        vaspRegister.callback_validate_addr_url =
          (vaspRegister.callback_validate_addr_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'callback_txid_url')) {
        vaspRegister.callback_txid_url =
          (vaspRegister.callback_txid_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'callback_host_url')) {
        vaspRegister.callback_host_url =
          (vaspRegister.callback_host_url || '').replace('https://', '') || null;
      }
      if (_.has(vaspRegister, 'vasp_fixed_ip')) {
        vaspRegister.vasp_fixed_ip = (vaspRegister.vasp_fixed_ip || []).join(',') || null;
      }
      if (_.has(vaspRegister, 'callback_vasp_server_health_check_url')) {
        vaspRegister.callback_vasp_server_health_check_url =
          (vaspRegister.callback_vasp_server_health_check_url || '').replace('https://', '') ||
          null;
      }
      if (_.has(vaspRegister, 'callback_vasp_server_health_check_url')) {
        vaspRegister.callback_vasp_server_health_check_url =
          (vaspRegister.callback_vasp_server_health_check_url || '').replace('https://', '') ||
          null;
      }

      if (_.has(vaspRegister, 'is_gtr_active')) {
        setIsGtrActived(vaspRegister.is_gtr_active);
      }
      if (_.has(vaspRegister, 'is_code_active')) {
        setIsCODEActive(vaspRegister.is_code_active);
      }

      vaspRegister.raw_receivers = [];
      vaspRegister.raw_assets = [];

      if (regType !== VaspRegisterType.EmailProtocol) {
        setVaspDetail(vaspRegister);
        reset(vaspRegister);
        return;
      }

      const currencyOptions = data.getCurrencies.map((currency) => ({
        ...currency,
        groupTitle: currency.is_token ? 'token' : '',
      }));

      (vaspRegister.notification_receivers || []).forEach((e) => {
        vaspRegister.raw_receivers.push({ value: e });
      });

      const currencyCache = {};
      (vaspRegister.assets || []).forEach((asset) => {
        let targetCurrency = currencyCache[asset.currency_id];
        if (!targetCurrency) {
          const currency = currencyOptions.find((c) => c.currency_id === asset.currency_id);
          currencyCache[asset.currency_id] = currency;
          targetCurrency = currency;
        }

        vaspRegister.raw_assets.push({
          currency: {
            ...targetCurrency,
          },
          address: asset.address,
          raw_extra_info: (asset.addr_extra_info || []).reduce((arr, cur) => {
            Object.entries(cur).forEach((e) => {
              arr.push({
                key: e[0],
                value: e[1],
              });
            });
            return arr;
          }, []),
        });
      });
      if (vaspRegister.raw_assets.length === 0) {
        vaspRegister.raw_assets.push({
          currency: {
            groupTitle: '',
            currency_id: 'sygna:0x80000000',
            currency_symbol: 'BTC',
            currency_name: 'Bitcoin',
            is_active: true,
            is_token: false,
            addr_extra_info: [],
          },
          address: '',
          raw_extra_info: [],
        });
      }

      setVaspDetail(vaspRegister);
      setCurrencies(currencyOptions);
      reset(vaspRegister);
    },
    onError: (e) => {
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    },
    fetchPolicy: 'no-cache',
  });

  const getUpdateRequest = useQuery(GET_UPDATE_REQUEST, {
    variables: {
      id,
    },
    context: {
      headers: {
        authorization: token,
      },
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (getReview.loading || getCurrencies.loading || getUpdateRequest.loading) return;

    const existed = _.get(getUpdateRequest, 'data.getVaspUpdateRequest');
    if (existed) {
      setExistUpdateRequest(existed);
    }

    const vaspRegister = _.get(getReview, 'data.getReview', {});
    setRegType(vaspRegister.registration_type);
    setServiceName(getRegistrationType(regType));
    vaspRegister.vasp_code = getVaspCode(vaspRegister);
    if (_.has(vaspRegister, 'vasp_receive_url')) {
      vaspRegister.vasp_receive_url =
        (vaspRegister.vasp_receive_url || '').replace('https://', '') || null;
    }
    if (_.has(vaspRegister, 'callback_validate_addr_url')) {
      vaspRegister.callback_validate_addr_url =
        (vaspRegister.callback_validate_addr_url || '').replace('https://', '') || null;
    }
    if (_.has(vaspRegister, 'callback_txid_url')) {
      vaspRegister.callback_txid_url =
        (vaspRegister.callback_txid_url || '').replace('https://', '') || null;
    }
    if (_.has(vaspRegister, 'callback_host_url')) {
      vaspRegister.callback_host_url =
        (vaspRegister.callback_host_url || '').replace('https://', '') || null;
    }
    if (_.has(vaspRegister, 'vasp_fixed_ip')) {
      if (Array.isArray(vaspRegister.vasp_fixed_ip)) {
        vaspRegister.vasp_fixed_ip = vaspRegister.vasp_fixed_ip.join(',') || null;
      }
    }

    if (_.has(vaspRegister, 'pem_pubkey')) {
      vaspRegister.pem_pubkey =
        (vaspRegister.pem_pubkey || '').replace(/[-]*BEGIN PUBLIC KEY[-\n]*/, '') || null;
      vaspRegister.pem_pubkey =
        (vaspRegister.pem_pubkey || '').replace(/[\n-]*END PUBLIC KEY[-]*/, '') || null;
    }
    vaspRegister.raw_receivers = [];
    vaspRegister.raw_assets = [];

    if (regType !== VaspRegisterType.EmailProtocol) {
      setVaspDetail(vaspRegister);
      reset(vaspRegister);
      return;
    }

    const currencyOptions = _.get(getCurrencies, 'data.getCurrencies', []).map((currency) => ({
      ...currency,
      groupTitle: currency.is_token ? 'token' : '',
    }));

    (vaspRegister.notification_receivers || []).forEach((e) => {
      vaspRegister.raw_receivers.push({ value: e });
    });

    const currencyCache = {};
    (vaspRegister.assets || []).forEach((asset) => {
      let targetCurrency = currencyCache[asset.currency_id];
      if (!targetCurrency) {
        const currency = currencyOptions.find((c) => c.currency_id === asset.currency_id);
        currencyCache[asset.currency_id] = currency;
        targetCurrency = currency;
      }

      vaspRegister.raw_assets.push({
        currency: {
          ...targetCurrency,
        },
        address: asset.address,
        raw_extra_info: (asset.addr_extra_info || []).reduce((arr, cur) => {
          Object.entries(cur).forEach((e) => {
            arr.push({
              key: e[0],
              value: e[1],
            });
          });
          return arr;
        }, []),
      });
    });
    if (vaspRegister.raw_assets.length === 0) {
      vaspRegister.raw_assets.push({
        currency: {
          groupTitle: '',
          currency_id: 'sygna:0x80000000',
          currency_symbol: 'BTC',
          currency_name: 'Bitcoin',
          is_active: true,
          is_token: false,
          addr_extra_info: [],
        },
        address: '',
        raw_extra_info: [],
      });
    }

    setVaspDetail(vaspRegister);
    setCurrencies(currencyOptions);
    reset(vaspRegister);
  }, [getReview.loading, getCurrencies.loading, getUpdateRequest.loading]);

  const classes = useStyles();
  const [submitForm] = useMutation(CREATE_UPDATE_REQUEST);

  const timer = React.useRef();
  const companyIDRef = useRef();
  const goLiveDtLabelRef = useRef();
  const accountExpirationRef = useRef();

  const onSubmit = async (data) => {
    let formData = data;
    if (confirmDialogOpen) {
      setConfirmDialogOpen(false);
      if (formData === null || formData === undefined) {
        formData = getValues();
      }
    } else if (existUpdateRequest) {
      setConfirmDialogOpen(true);
      return;
    }

    const submitData = _.assignWith(vaspDetail, formData, (objValue, srcValue) =>
      !srcValue && srcValue !== false && srcValue !== 0 ? objValue : srcValue,
    );
    if (regType === VaspRegisterType.EmailProtocol) {
      const { raw_assets: rawAssets, raw_receivers: receivers } = submitData;
      if (!receivers || receivers.length === 0) {
        setFormError(`raw_receivers`, {
          type: 'empty',
        });
        return;
      }

      const appendIndexAssets = rawAssets.map((asset, i) => ({ ...asset, index: i }));
      const currencyIDGroups = _.groupBy(appendIndexAssets, (asset) => asset.currency.currency_id);
      const assetGroups = Object.values(currencyIDGroups);

      let isValid = true;
      assetGroups.forEach((assets) => {
        const addressExists = {};
        assets.forEach((asset) => {
          const extraInfos = (asset.raw_extra_info || []).map((e) => `${e.key}=${e.value}`);
          const extraInfoExpression = extraInfos.join('&');
          const fullAddress = asset.address + (extraInfoExpression && `?${extraInfoExpression}`);

          if (addressExists[fullAddress]) {
            // address already exists , should show an error which address is duplicated
            setFormError(`raw_assets[${asset.index}].address`, {
              type: 'duplicated',
            });
            // isValid = false;
            if (isValid) {
              isValid = false;
            }
          } else {
            addressExists[fullAddress] = true;
          }
        });
      });
      if (!isValid) {
        return;
      }
    }

    setCreateLoading(true);
    try {
      const input = transferDataToSubmitArg(submitData);
      delete input.vasp_code_prefix;
      delete input.country_code;
      delete input.city_code;
      delete input.branch_code;
      await submitForm({
        variables: {
          input: {
            ...input,
            registration_type: vaspDetail.registration_type,
            id: vaspDetail.id,
            vasp_email: submitData.vasp_email,
            vasp_name: submitData.vasp_name,
            account_expiration_dt: submitData.account_expiration_dt,
            vasp_status: vaspDetail.vasp_status,
            vasp_code: vaspDetail.vasp_code,
            vasp_code_prefix: vaspDetail.vasp_code_prefix,
            country_code: vaspDetail.country_code,
            city_code: vaspDetail.city_code,
            branch_code: vaspDetail.branch_code,
          },
        },
        context: {
          headers: {
            authorization: token,
          },
        },
      });

      window.onbeforeunload = null;
      setCreateLoading(false);
      setCreateSuccess(true);
      setInformDialogOpen(true);
    } catch (e) {
      setCreateLoading(false);
      setErrorDialogOpen(true);
      setError(parseGraphQLErrorMessage(e));
    }
  };

  const onError = (validateErrors) => {
    if (validateErrors.vasp_category) {
      scrollToRef(companyIDRef);
    }
    if (validateErrors.go_live_dt) {
      scrollToRef(goLiveDtLabelRef);
    }
    if (validateErrors.account_expiration_dt) {
      scrollToRef(accountExpirationRef);
    }
  };

  useEffect(() => {
    window.onbeforeunload = (e) => {
      const dialogText =
        'This page is asking you to confirm that you want to leave - data you have entered may not be saved.';
      e.returnValue = dialogText;
      return dialogText;
    };

    return () => {
      clearTimeout(timer.current);
      window.onbeforeunload = null;
    };
  }, []);

  const renderFormFieldByType = () => {
    switch (regType) {
      case VaspRegisterType.Bridge:
        return (
          <Bridge
            goLiveDefaultValue={vaspDetail.go_live_dt}
            goLiveForwardedRef={goLiveDtLabelRef}
            goLiveDisabled={vaspDetail.vasp_status === OperationStatus.Merged}
          />
        );
      case VaspRegisterType.Hub:
        return (
          <Hub
            goLiveDefaultValue={vaspDetail.go_live_dt}
            goLiveForwardedRef={goLiveDtLabelRef}
            goLiveDisabled={vaspDetail.vasp_status === OperationStatus.Merged}
          />
        );

      case VaspRegisterType.EmailProtocol:
        return <EmailProtocol currencies={currencies} />;

      default:
        return null;
    }
  };

  return (
    <Layout>
      <AuthenticatedPage>
        <div className="application">
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
          </Helmet>
        </div>
        <Navigator
          breadcrumbTexts={['Home', 'Approve VASP List', 'Approve Vasp']}
          breadcrumbLinks={['/', '/approve-vasp-list', '/approve-vasp']}
        />
        <br />
        <FormProvider {...hookFormProps}>
          <div className={classes.content}>
            <header className={classes.header} role="banner">
              <div className={classes.headerContainer}>
                <Logo className={classes.logo} registrationType={regType} />
              </div>
            </header>
            <div id="main">
              <div className={classes.container}>
                <div className={classes.intro}>
                  <h2 className={classes.introTitle}>Welcome to {serviceName} VASP Registration</h2>
                </div>
                <br />
                <div className={classes.container}>
                  <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
                    <MarginVerticalSection>
                      <RequiredLabel htmlFor="vasp_email" value="Email Address" isRequired />
                      <HookFormTextField
                        id="vasp_email"
                        name="vasp_email"
                        placeholder="Input estimated number of customers"
                        forwardedRef={register({ required: true })}
                        error={errors.vasp_email}
                        onChange={(e) => setValue('vasp_email', e.target.value)}
                      />
                    </MarginVerticalSection>
                    <MarginVerticalSection>
                      <RequiredLabel htmlFor="vasp_name" value="Company Name" isRequired />
                      <HookFormTextField
                        id="vasp_name"
                        name="vasp_name"
                        placeholder='Your registered company name. Example: "VASP LTD."'
                        forwardedRef={register({
                          required: true,
                        })}
                        error={errors.vasp_name}
                        onChange={(e) => setValue('vasp_name', e.target.value)}
                      />
                    </MarginVerticalSection>
                    <MarginVerticalSection>
                      <RequiredLabel htmlFor="vasp_code" value="VASP Code" isRequired />
                      <HookFormTextField
                        id="vasp_code"
                        name="vasp_code"
                        placeholder="Input VASP code Name"
                        disabled
                        paddingLabel={20}
                        forwardedRef={register({
                          required: true,
                        })}
                        error={errors.vasp_code}
                        parseError={(e) => {
                          switch (_.get(e, ['type'], undefined)) {
                            case 'required':
                              return ErrorMessages.Required;
                            case 'validate':
                              return ErrorMessages.ExistedVaspCode;
                            case undefined:
                              return '';
                            default:
                              return ErrorMessages.InvalidUpperCaseLettersLength(4);
                          }
                        }}
                      />
                    </MarginVerticalSection>
                    <MarginVerticalSection>
                      <RequiredLabel
                        htmlFor="company_id"
                        value="Company ID"
                        forwardedRef={companyIDRef}
                      />
                      <HookFormTextField
                        id="company_id"
                        name="company_id"
                        fullWidth
                        label="Company registration code under local jurisdiction."
                        placeholder="Input company ID"
                        forwardedRef={register()}
                        onChange={(e) => setValue('company_id', e.target.value)}
                      />
                    </MarginVerticalSection>

                    <MarginVerticalSection>
                      <RequiredLabel htmlFor="vasp_category" value="VASP Category" isRequired />
                      <Controller
                        render={({ ...props }) => (
                          <div className={classes.root}>
                            <FormControl
                              className={classes.formControl}
                              error={!!errors.vasp_category}
                            >
                              <Select
                                {...props}
                                fullWidth
                                className={classes.normalFontSize}
                                id="vasp_category"
                              >
                                <MenuItem value="Virtual/Fiat">Virtual/Fiat</MenuItem>
                                <MenuItem value="Virtual/Virtual">Virtual/Virtual</MenuItem>
                                <MenuItem value="Transfer">Transfer</MenuItem>
                                <MenuItem value="Custodian">Custodian</MenuItem>
                                <MenuItem value="Other">Other</MenuItem>
                              </Select>
                              <FormHelperText id="vasp_category-helper-text">
                                {errors.vasp_category && ErrorMessages.Required}
                              </FormHelperText>
                            </FormControl>
                          </div>
                        )}
                        name="vasp_category"
                        control={control}
                        rules={{
                          required: true,
                        }}
                        defaultValue={vaspDetail.vasp_category}
                      />
                    </MarginVerticalSection>

                    <MarginVerticalSection>
                      <RequiredLabel
                        htmlFor="estimated_customer_number"
                        value="Estimated Number Of Customers"
                        isRequired
                      />
                      <HookFormTextField
                        id="estimated_customer_number"
                        name="estimated_customer_number"
                        fullWidth
                        type="number"
                        placeholder="Input estimated number of customers"
                        forwardedRef={register({
                          required: true,
                          validate: (v) => v > 0,
                        })}
                        parseError={(e) => {
                          const type = _.get(e, ['type'], undefined);
                          if (!type) {
                            return '';
                          }
                          if (type === 'required') {
                            return ErrorMessages.Required;
                          }
                          return ErrorMessages.InvalidEstimateValue;
                        }}
                        error={errors.estimated_customer_number}
                        onChange={(e) => setValue('estimated_customer_number', e.target.value)}
                      />
                    </MarginVerticalSection>

                    <MarginVerticalSection>
                      <RequiredLabel
                        htmlFor="estimated_annual_transaction_volume"
                        value="Estimated Annual Transaction Volume (in US$)"
                        isRequired
                      />
                      <HookFormTextField
                        id="estimated_annual_transaction_volume"
                        name="estimated_annual_transaction_volume"
                        fullWidth
                        type="number"
                        inputProps={{ step: 'any' }}
                        placeholder="Input estimated annual transaction volume (in US$)"
                        forwardedRef={register({
                          required: true,
                          validate: (v) => v > 0,
                        })}
                        parseError={(e) => {
                          const type = _.get(e, ['type'], undefined);
                          if (!type) {
                            return '';
                          }
                          if (type === 'required') {
                            return ErrorMessages.Required;
                          }
                          return ErrorMessages.InvalidEstimateValue;
                        }}
                        error={errors.estimated_annual_transaction_volume}
                        onChange={(e) =>
                          setValue('estimated_annual_transaction_volume', e.target.value)
                        }
                      />
                    </MarginVerticalSection>

                    <MarginVerticalSection>
                      <RequiredLabel
                        htmlFor="estimated_annual_travel_rule_data_transfer_number"
                        value="Estimated Annual Number Of Travel Rule Data Transfers"
                        isRequired
                      />
                      <HookFormTextField
                        id="estimated_annual_travel_rule_data_transfer_number"
                        name="estimated_annual_travel_rule_data_transfer_number"
                        fullWidth
                        type="number"
                        placeholder="Input estimated annual number of Travel Rule data transfers"
                        forwardedRef={register({
                          required: true,
                          validate: (v) => v > 0,
                        })}
                        parseError={(e) => {
                          const type = _.get(e, ['type'], undefined);
                          if (!type) {
                            return '';
                          }
                          if (type === 'required') {
                            return ErrorMessages.Required;
                          }
                          return ErrorMessages.InvalidEstimateValue;
                        }}
                        error={errors.estimated_annual_travel_rule_data_transfer_number}
                        onChange={(e) =>
                          setValue(
                            'estimated_annual_travel_rule_data_transfer_number',
                            e.target.value,
                          )
                        }
                      />
                    </MarginVerticalSection>

                    {renderFormFieldByType()}

                    <AccountExpiration forwardedRef={accountExpirationRef} />
                    {vaspDetail.registration_type === VaspRegisterType.Gate ||
                    vaspDetail.registration_type === VaspRegisterType.Hub ? (
                      <div>
                        <div style={{ padding: 8 }}>
                          <FormLabel component="legend">
                            <strong>Interoperability</strong>
                          </FormLabel>
                          <FormLabel component="legend" style={{ marginTop: 10 }}>
                            Questionnaire result from registration:{vaspDetail.paidInteroperability}
                          </FormLabel>

                          <div>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      setValue('is_gtr_active', e.target.checked);
                                      setIsGtrActived(e.target.checked);
                                    }}
                                    name="is_gtr_active"
                                    color="primary"
                                    forwardedRef={register('is_gtr_active', {
                                      required: false,
                                    })}
                                    checked={isGtrActived}
                                  />
                                }
                                label="Global Travel Rule (GTR)"
                              />
                            </FormGroup>
                            <Controller
                              render={({ onChange, ...props }) => (
                                <CustomDateTimePicker
                                  {...props}
                                  id="gtr_expired_date"
                                  label="GTR Expiration Date"
                                  disablePast
                                  selectedDate={_.get(props, ['value'])}
                                  onDateChange={(val) => {
                                    onChange(val);
                                  }}
                                  error={errors.gtr_expired_date}
                                  parseError={(e) => {
                                    const type = _.get(e, ['type'], undefined);
                                    if (!type) {
                                      return '';
                                    }
                                    if (type === 'isValidDate') {
                                      return ErrorMessages.InvalidDateFormat;
                                    }
                                    return ErrorMessages.InvalidGoLiveDt;
                                  }}
                                  clearable
                                  allowKeyboardControl
                                  paddingLabel={3}
                                />
                              )}
                              name="gtr_expired_date"
                              onChange={(val) => {
                                setValue('gtr_expired_date', val);
                              }}
                              control={control}
                              defaultValue={
                                vaspDetail.gtr_expired_date || moment().second(0).millisecond(0)
                              }
                            />
                          </div>
                          <div>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    onChange={(e) => {
                                      setValue('is_code_active', e.target.checked);
                                      setIsCODEActive(e.target.checked);
                                    }}
                                    name="is_code_active"
                                    color="primary"
                                    forwardedRef={register('is_code_active', {
                                      required: false,
                                    })}
                                    checked={isCODEActive}
                                  />
                                }
                                label="CODE"
                              />
                            </FormGroup>
                            <Controller
                              render={({ onChange, ...props }) => (
                                <CustomDateTimePicker
                                  {...props}
                                  id="code_expired_date"
                                  label="CODE Expiration Date"
                                  disablePast
                                  selectedDate={_.get(props, ['value'])}
                                  onDateChange={(val) => {
                                    onChange(val);
                                  }}
                                  error={errors.code_expired_date}
                                  parseError={(e) => {
                                    const type = _.get(e, ['type'], undefined);
                                    if (!type) {
                                      return '';
                                    }
                                    if (type === 'isValidDate') {
                                      return ErrorMessages.InvalidDateFormat;
                                    }
                                    return ErrorMessages.InvalidGoLiveDt;
                                  }}
                                  clearable
                                  allowKeyboardControl
                                  paddingLabel={3}
                                />
                              )}
                              name="code_expired_date"
                              onChange={(val) => {
                                setValue('code_expired_date', val);
                              }}
                              control={control}
                              defaultValue={
                                vaspDetail.code_expired_date || moment().second(0).millisecond(0)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className={classes.ctaBlock}>
                      <p className={classes.ctaBlockText}>
                        If you have any questions with the information in this email, please let us
                        know at&nbsp;
                        <a
                          href="mailto:services@sygna.io"
                          style={{ color: '#0091ff', transition: '0.5s' }}
                        >
                          services@sygna.io
                        </a>
                        &nbsp;or visit our website.
                      </p>
                      <div className="cta" style={{ textAlign: 'center' }}>
                        <LoadingButton
                          id="submit"
                          loading={createLoading}
                          done={{
                            get: createSuccess,
                            set: setCreateSuccess,
                          }}
                          buttonType="submit"
                          buttonText="Submit"
                          buttonClassName={classes.ctaBtn}
                          buttonProgressClassName={classes.buttonProgress}
                          disabled={
                            regType === VaspRegisterType.EmailProtocol &&
                            (!currencies || currencies.length === 0)
                          }
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ErrorDialog
            id="error_dialog"
            open={{ get: errorDialogOpen, set: setErrorDialogOpen }}
            message={error}
          />
          <CustomDialog
            id="confirm_dialog"
            open={{ get: informDialogOpen, set: setInformDialogOpen }}
            title="Inform"
            message="submit success"
            positiveButtonText="ok"
            onClose={() => {
              setInformDialogOpen(false);
              const parameter = { id };
              navigate('/approve-vasp', { state: { parameter } });
            }}
            timeout={5000}
          />
          <CustomDialog
            open={{ get: confirmDialogOpen, set: setConfirmDialogOpen }}
            title="Confirm"
            message="Are you sure to submit and replace existed request?"
            positiveButtonText="submit"
            onPositiveButtonClick={onSubmit}
            negativeButtonText="cancel"
            onClose={() => {
              setConfirmDialogOpen(false);
            }}
            timeout={5000}
          />
        </FormProvider>
      </AuthenticatedPage>
    </Layout>
  );
}

CreateVaspUpdateRequest.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      parameter: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
  }),
};
