import React, { useState } from 'react';
import { useFormContext, useFieldArray, Controller } from 'react-hook-form';
import { InputAdornment, IconButton, Chip, Grid } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { MarginVerticalSection } from '../../styled';
import { RequiredLabel, ResizeTextField } from '../../common';
import { ErrorMessages } from '../../../constants';
import { isEmailValid } from '../../../utils/utils';

const useStyles = makeStyles(() => ({
  chipGrid: {
    '& .MuiChip-root': {
      backgroundColor: 'rgba(16, 73, 53, 0.25)',
      wordBreak: 'break-word',
      minHeight: '32px',
      whiteSpace: 'normal',
      height: 'auto',
    },
    '& .MuiChip-label': {
      overflow: 'auto',
      whiteSpace: 'normal',
    },
  },
}));

export default function Receiver() {
  const classes = useStyles();
  const { control, clearErrors, errors } = useFormContext();
  const [receiver, setReceiver] = useState('');
  const [error, setError] = useState(false);

  const {
    fields: receivers,
    append: appendReceivers,
    remove: removeReceivers,
  } = useFieldArray({
    control,
    name: 'raw_receivers',
  });

  const handleClicked = () => {
    const isValidEmail = isEmailValid(receiver);
    if (!isValidEmail) {
      setError(true);
      return;
    }
    const isDuplicated = receivers.some((r) => r.value === receiver.toLowerCase());
    if (isDuplicated) {
      return;
    }
    appendReceivers({ value: receiver.toLowerCase() });
    setReceiver('');
    clearErrors('raw_receivers');
  };

  const renderChips = () => {
    if (receivers.length === 0) {
      return null;
    }
    return (
      <Grid container spacing={1} style={{ marginTop: '0.2em' }} className={classes.chipGrid}>
        {receivers.map((v, index) => (
          <Grid item key={v.id}>
            <Controller
              as={<Chip />}
              control={control}
              name={`raw_receivers[${index}].value`}
              defaultValue={receivers[index].value || ''}
              id={`chip_receiver-${v.value}`}
              label={v.value}
              onDelete={() => {
                removeReceivers(index);
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <MarginVerticalSection>
      <RequiredLabel htmlFor="vasp_api" value="Travel Rule Data Exchange Email Address" />
      <ResizeTextField
        id="raw_receiver"
        name="raw_receiver"
        fullWidth
        label="Your email addresses to receive the Travel Rule Email Protocol confirmation links."
        placeholder="Input email address"
        onChange={(e) => {
          setReceiver(e.target.value);
          setError(false);
          clearErrors('raw_receivers');
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClicked} disabled={receiver.length === 0}>
                <AddIcon id="add_receiver_btn" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={receiver}
        error={!!error || !!errors.raw_receivers}
        helperText={
          (!!error && ErrorMessages.InvalidEmailFormat) ||
          (_.get(errors, ['raw_receivers', 'type'], undefined)
            ? ErrorMessages.InvalidEmailAddressesEmpty
            : '')
        }
      />
      {renderChips()}
    </MarginVerticalSection>
  );
}
