import React from 'react';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { MarginVerticalSection } from '../styled';
import { RequiredLabel, HookFormTextField } from '../common';
import { ErrorMessages } from '../../constants';
import { isValidIP } from '../../utils/utils';

export default function FixedIP() {
  const { setValue, errors, register } = useFormContext();
  return (
    <MarginVerticalSection>
      <RequiredLabel htmlFor="ip_address" value="Fixed static IP Address" />
      <HookFormTextField
        id="vasp_fixed_ip"
        name="vasp_fixed_ip"
        label={
          <>
            To create a secure communication tunnel between Bridge and VASP servers, Bridge can
            register VASP server static IP for identification. (optional)
            <br />
            Multiple IP addresses are allowed. Please enter a comma (,) to separate each IP address.
          </>
        }
        placeholder="Input fixed static IP address"
        paddingLabel={40}
        onChange={(e) => setValue('vasp_fixed_ip', e.target.value)}
        forwardedRef={register({
          validate: isValidIP,
        })}
        error={errors.vasp_fixed_ip}
        parseError={(e) => {
          const type = _.get(e, ['type'], undefined);
          if (!type) {
            return '';
          }
          return ErrorMessages.InvalidIPAddressFormat;
        }}
      />
    </MarginVerticalSection>
  );
}
